import axios from '../../http/axios/index'

export default {
  namespaced: true,
  state: {
    listImport: [],
    totalItem: 0,
    count: 0
  },
  mutations: {
    SET_LIST_IMPORT (state, data) {
      state.listImport = data
    },
    SET_TOTAL_ITEM (state, count) {
      state.totalItem = count
    },
    SET_TOTAL_COUNT (state, count) {
      state.count = count
    }
  },
  actions: {
    getById ({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .post('import/get', item)
          .then(response => {
            resolve(response.data)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    getAll () {
      return new Promise((resolve, reject) => {
        axios
          .get('import/')
          .then(response => {
            resolve(response.data)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    ConfirmProduct ({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .post('product/import', item)
          .then(response => {
            resolve(response.data)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    Remove ({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .post('import/remove', item)
          .then(response => {
            resolve(response.data)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    Count ({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('import/count')
          .then(response => {
            commit('SET_TOTAL_COUNT', response.data.total)
            resolve(response.data)
          })
          .catch(err => {
            reject(err)
          })
      })
    }
  }// END Actions
}
