// import jwt from '../../http/requests/auth/jwt/index.js'
// import router from '@/router'
// import Vue from 'vue'
// import Vuex from 'vuex'

import axios from '../../http/axios'

export default {
  namespaced: true,
  state: {
    isRegistered: false,
    listCustomer: [],
    listAgent: [],
    customerForEdit: [],
    count: 0
  },
  mutations: {
    SET_LIST_CUSTOMER (state, item) {
      state.listCustomer = item
    },
    SET_COUNT_ITEM (state, item) {
      state.count = item
    },
    SET_CUSTOMER_BLANK (state) {
      state.listCustomer = []
      state.count = 0
    },
    SET_CUSTOMER_FOR_EDIT (state, item) {
      state.customerForEdit = item
    },
    SET_LIST_AGENT (state, item) {
      state.agent = item
    }
  },
  actions: {
    getCustomerList ({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .get('customer/getlistcustomer')
          .then(response => {
            // commit('SET_LIST_CUSTOMER', response.data.items)
            // commit('SET_COUNT_ITEM', response.data.totalItem)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getCustomerByCode ({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .post('customer/get', item)
          .then(response => {
            commit('SET_CUSTOMER_FOR_EDIT', response.data.item)
            resolve(response.data.item)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getCustomer ({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('customer/getcustomer')
          .then(response => {
            commit('SET_LIST_CUSTOMER', response.data.items)
            commit('SET_COUNT_ITEM', response.data.totalItem)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    }, // END get customer
    getAgent ({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .post('customer/getagent', item)
          .then(response => {
            commit('SET_LIST_AGENT', response.data.items)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getAgentOverdue ({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .post('customer/getagentoverdue', item)
          .then(response => {
            commit('SET_LIST_AGENT', response.data.items)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getagentoverduebyid ({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .post('customer/getagentoverduebyid', item)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    addCustomer ({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .post('customer/add', item)
          .then(response => {
            if (response.data.success) {
              commit('SET_COUNT_ITEM')
              commit('SET_CUSTOMER_BLANK')
            }
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    }, // END add customer
    getCountry ({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('country/getall')
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    addCountry ({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .post('country/add', item)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    updateCustomer ({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .put('customer/edit', item)
          .then(response => {
            if (response.data.success) {
              commit('SET_CUSTOMER_BLANK')
            }
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    }, // END update Customer
    removeCustomer ({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .put('customer/remove', item)
          .then(response => {
            if (response.data.success) {
              commit('SET_CUSTOMER_BLANK')
            }
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    }, // END remove Customer
    addBankAccount ({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .post('customer/bank/update', item)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    changeDefaultAddress ({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .post('customer/address/select', item)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    }
  }

}
