import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import VueSweetalert2 from 'vue-sweetalert2'

import VueNumberFormat from 'vue-number-format'
// import moment from 'moment'
import momentimezone from 'moment-timezone'

import LightGallery from 'vue-light-gallery'
import { library, config } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

// Axios Mock Adapter
import '@/@fake-db/db'
import data_bank from '@/data-json/bank.json'

config.autoAddCss = false
library.add(fas)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

Vue.use(LightGallery)
const NumAbbr = require('number-abbreviate')

// Global Properties
Vue.prototype.$baseURL = process.env.VUE_APP_SERVER_URL
Vue.prototype.$UrlImg = process.env.VUE_APP_SERVER_URL

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

Vue.use(VueSweetalert2)
Vue.use(VueNumberFormat, { prefix: '', precision: 0, thousand: ',', acceptNegative: true })

Vue.filter('toPercen', value => {
  const price = parseFloat(value[0].price)
  const percen = parseFloat(value[0].percen)
  const result = price - ((price / 100) * percen)
  const formatter = new Intl.NumberFormat('th', { minimumFractionDigits: 2 })
  return formatter.format(result)
})
Vue.filter('toPercenDiscount', value => {
  const price = parseFloat(value[0].price)
  const percen = parseFloat(value[0].percen)
  const discount = parseFloat(value[0].discount)
  const specialDiscount = parseFloat(value[0].specialDiscount)
  const result = ((price - specialDiscount) - ((price / 100) * (percen + discount)))
  const formatter = new Intl.NumberFormat('th', { minimumFractionDigits: 2 })
  return formatter.format(result)
})
Vue.filter('toPriceDiff', value => {
  const price = parseFloat(value[0].price)
  const percen = parseFloat(value[0].percen)
  const discount = parseFloat(value[0].discount)
  const specialDiscount = parseFloat(value[0].specialDiscount)
  const result = price - ((price - specialDiscount) - ((price / 100) * (percen + discount)))
  const formatter = new Intl.NumberFormat('th', { minimumFractionDigits: 2 })
  return formatter.format(result)
})

Vue.filter('nameAccount', value => {
  console.log(value)
  const data = data_bank.RECORDS.filter(x => x.code === value)
  return data.length > 0 ? data[0].name : ''
})

Vue.filter('almostSuccessEvent', value => {
  if (Array.isArray(value)) {
    const finalCount = value.reduce((acc, cur) => {
      const count = cur.progress.reduce((a, p) => {
        if (p.percent >= 80 && p.percent < 100) {
          return a + 1
        }
        return a
      }, 0)
      return acc + count
    }, 0)
    return finalCount > 0 ? `${finalCount}` : ''
  }
  return ''
})
Vue.filter('successEvent', value => {
  if (Array.isArray(value)) {
    const finalCount = value.reduce((acc, cur) => {
      const count = cur.progress.reduce((a, p) => {
        if (p.percent >= 100 && !cur.event.claimRewards.some(c => c.id === p.customer._id)) {
          return a + 1
        }
        return a
      }, 0)
      return acc + count
    }, 0)
    return finalCount > 0 ? `${finalCount}` : ''
  }
  return ''
})

Vue.filter('toCurrency', value => {
  let dataVal = value
  if (typeof dataVal !== 'number') {
    dataVal = parseFloat(dataVal)
  }
  const formatter = new Intl.NumberFormat('th', { minimumFractionDigits: 2 })
  return formatter.format(dataVal)
})
Vue.filter('toAmount', value => {
  let dataVal = value
  if (typeof dataVal !== 'number') {
    dataVal = parseFloat(dataVal)
  }
  const formatter = new Intl.NumberFormat('th', { minimumFractionDigits: 0 })
  return formatter.format(dataVal)
})
Vue.filter('toCurrencyBath', value => {
  let dataVal = value
  if (typeof value !== 'number') {
    dataVal = parseFloat(dataVal)
  }
  const formatter = new Intl.NumberFormat('th', { minimumFractionDigits: 2 })
  return `${formatter.format(dataVal)} ฿`
})

Vue.filter('formatDate', value => {
  if (value) {
    return momentimezone.tz(value, 'Asia/Bangkok').locale('th').format('LLL')
  }
})
Vue.filter('formatDateSort', value => {
  if (value) {
    return `${momentimezone.tz(value, 'Asia/Bangkok').locale('th').format('ll')} ${momentimezone.tz(value, 'Asia/Bangkok').locale('th').format('LT')}`
  }
})
Vue.filter('formatDateSortNotime', value => {
  if (value) {
    return `${momentimezone.tz(value, 'Asia/Bangkok').locale('th').format('ll')}`
  }
})

Vue.filter('toAbbreviate', value => {
  // eslint-disable-next-line no-undef
  const numAbbr = new NumAbbr(['k', 'm', 'b', 't'])
  return `${numAbbr.abbreviate(value, 2)}`
})

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
