import axios from '../../http/axios/index'

export default {
  namespaced: true,
  state: {
    listProduct: [],
    totalItem: 0,
    orderRequire: 0,
    orderOverduePaymentAgent: 0
  },
  mutations: {
    SET_LIST_PRODUCT (state, data) {
      state.listProduct = data
    },
    SET_TOTAL_ITEM (state, count) {
      state.totalItem = count
    },
    SET_TOTAL_orderRequire (state, count) {
      state.orderRequire = count
    },
    SET_TOTAL_orderOverduePaymentAgent (state, count) {
      state.orderOverduePaymentAgent = count
    }
  },
  actions: {
    addOrder ({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .post('order/add', item)
          .then(response => {
            resolve(response)
          }).catch(err => {
            reject(err)
          })
      })
    },
    getboxes ({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .post('order/getboxes', item)
          .then(response => {
            resolve(response)
          }).catch(err => {
            reject(err)
          })
      })
    },
    getcountrequire ({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('order/getcountrequire')
          .then(response => {
            commit('SET_TOTAL_orderRequire', response.data.item[0])
            commit('SET_TOTAL_orderOverduePaymentAgent', response.data.item[1])

            resolve(response)
          }).catch(err => {
            reject(err)
          })
      })
    },
    getOrder ({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .post('order/getorder', item)
          .then(response => {
            resolve(response)
          }).catch(err => {
            reject(err)
          })
      })
    },
    getOrderIDPay ({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .post('order/getorderpayment', item)
          .then(response => {
            resolve(response)
          }).catch(err => {
            reject(err)
          })
      })
    },
    getorderpayment ({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .post('order/getorderpayment', item)
          .then(response => {
            resolve(response)
          }).catch(err => {
            reject(err)
          })
      })
    },
    confirmOrdermanagement ({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .post('order/updatestatus', item)
          .then(response => {
            resolve(response)
          }).catch(err => {
            reject(err)
          })
      })
    },
    updatestatusProgress ({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .post('order/updatestatusProgress', item)
          .then(response => {
            resolve(response)
          }).catch(err => {
            reject(err)
          })
      })
    },
    updatestatusLogis ({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .post('order/updatelogis', item)
          .then(response => {
            resolve(response)
          }).catch(err => {
            reject(err)
          })
      })
    },
    confirmCancelOrdermanagement ({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .post('order/cancel', item)
          .then(response => {
            resolve(response)
          }).catch(err => {
            reject(err)
          })
      })
    },
    gettransaction ({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .post('order/gettransaction', item)
          .then(response => {
            resolve(response)
          }).catch(err => {
            reject(err)
          })
      })
    },
    addOrderPayment ({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .post('order/addtransaction', item)
          .then(response => {
            resolve(response.data)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    updateOrderPayment ({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .post('order/updateTransaction', item)
          .then(response => {
            resolve(response.data)
          })
          .catch(err => {
            reject(err)
          })
      })
    }

  }// END Actions
}
