
import axios from '../../http/axios/index'

export default {
  namespaced: true,
  state: {
    listEvent: [],
    progressEventList: []
  },
  mutations: {
    SET_EVENT_LIST (state, data) {
      state.listEvent = data
    },
    SET_PROGRESS_EVENT_LIST (state, data) {
      state.progressEventList = data
    }
  },
  actions: {
    addEvent ({ _, dispatch }, item) {
      return new Promise((resolve, reject) => {
        axios.post('event', item)
          .then(() => dispatch('getEvent'))
          .then(() => resolve())
          .catch(err => {
            reject(err)
          })
      })
    },
    deleteEvent ({ _, dispatch }, item) {
      return new Promise((resolve, reject) => {
        axios.delete('event', {
          data: item
        })
          .then(() => dispatch('getEvent'))
          .then(() => resolve())
          .catch(err => {
            reject(err)
          })
      })
    },
    getEvent ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('event')
          .then(response => {
            commit('SET_EVENT_LIST', response.data.list)
            resolve(response)
          }).catch(err => {
            reject(err)
          })
      })
    },
    getProgress ({ _ }, id) {
      return new Promise((resolve, reject) => {
        axios.get(`event/${id}/progress`)
          .then(response => {
            resolve(response)
          }).catch(err => {
            console.log(err)
            reject(err)
          })
      })
    },
    getEachProgress ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('event/progress')
          .then(response => {
            commit('SET_PROGRESS_EVENT_LIST', response.data.list)
            resolve(response)
          }).catch(err => {
            console.log(err)
            reject(err)
          })
      })
    },
    claimReward ({ _, dispatch }, data) {
      return new Promise((resolve, reject) => {
        axios.put(`event/${data.eventId}/claimReward`, {
          customerId: data.customerId
        })
          .then(response => {
            dispatch('getEachProgress')
            resolve(response)
          }).catch(err => {
            console.log(err)
            reject(err)
          })
      })
    },
    unclaimReward ({ _, dispatch }, data) {
      return new Promise((resolve, reject) => {
        axios.put(`event/${data.eventId}/unclaimReward`, {
          customerId: data.customerId
        })
          .then(response => {
            dispatch('getEachProgress')
            resolve(response)
          }).catch(err => {
            console.log(err)
            reject(err)
          })
      })
    }
  }
}
