import axios from '../../http/axios/index'

export default {
  namespaced: true,
  actions: {
    getAllDiscount ({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('customer/getalldiscount')
          .then(response => {
            resolve(response)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    updateDiscount ({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .post('customer/updatediscount', item)
          .then(response => {
            resolve(response)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    getAllChannel ({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('payment/getall')
          .then(response => {
            resolve(response)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    getPosition ({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('position/getall')
          .then(response => {
            resolve(response)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    getAccount ({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('bank/getall')
          .then(response => {
            resolve(response)
          })
          .catch(err => {
            console.log(err)
            reject(err)
          })
      })
    },
    addChannel ({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .post('payment/add', item)
          .then(response => {
            resolve(response)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    addAccount ({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .post('bank/add', item)
          .then(response => {
            resolve(response)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    editAccount ({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .post('bank/edit', item)
          .then(response => {
            resolve(response)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    addPosition ({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .post('position/add', item)
          .then(response => {
            resolve(response)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    editChannel ({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .post('payment/edit', item)
          .then(response => {
            resolve(response)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    deleteAccount ({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .post('bank/remove', item)
          .then(response => {
            resolve(response)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    deleteChannel ({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .post('payment/remove', item)
          .then(response => {
            resolve(response)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    editPosition ({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .post('position/edit', item)
          .then(response => {
            resolve(response)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    deletePosition ({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .post('position/remove', item)
          .then(response => {
            resolve(response)
          })
          .catch(err => {
            reject(err)
          })
      })
    }
  }

}
