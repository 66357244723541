import axios from '../../http/axios/index'

export default {
  namespaced: true,
  state: {
    data: [],
    dataProductOrder: [],
    datas: []
  },
  mutations: {
    getDataList (state, data) {
      state.data = data
    },
    getDataProducOrderList (state, item) {
      const items = item.item
      const dataInputs = []
      items.forEach((data, index) => {
        console.log(item.warehouse)
        const setIndex = {
          index,
          priceProduct: 0
        }
        dataInputs.push({
          warehouse: item.warehouse,
          inputs: {
            amount: 0,
            warehouse: data.warehouseId,
            addQty: 0,
            product: data.product._id
          }
        })
        items[index] = { ...data, ...setIndex }
      })
      state.datas = dataInputs
      state.dataProductOrder = items
    },
    setStock (state, data) {
      state.dataProductOrder[data.index].amount = data.amount.toString()
    }

  },
  actions: {
    getProductAll ({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('product/getAll')
          .then(response => {
            console.log('response.data.items', response.data.items)
            commit('getDataList', response.data.items)
            resolve(response)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    getStockWhs ({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .post('warehouse/getwarehouse', item)
          .then(response => {
            commit('setStock', { index: item.index, amount: response.data.amount })
            resolve(response)
          })
          .catch(err => {
            reject(err)
          })
      })
    },

    getProductOrderAll ({ commit }, dataW) {
      return new Promise((resolve, reject) => {
        console.log('dataW', dataW)
        axios
          .get('product/getmore')
          .then(response => {
            commit('getDataProducOrderList', { item: response.data.items, warehouse: dataW })
            console.log('response.data.items', response.data.items)
            resolve(response)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    getProduct ({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .post('product/get', item)
          .then(response => {
            resolve(response)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    AddProduct  ({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.post('product/add', item)
          .then(response => {
            resolve(response)
          }).catch(err => {
            reject(err)
          })
      })
    },
    DeleteProduct  ({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.put('product/remove', item)
          .then(response => {
            resolve(response)
          }).catch(err => {
            reject(err)
          })
      })
    },
    editProduct  ({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.put('product/edit', item)
          .then(response => {
            resolve(response)
          }).catch(err => {
            reject(err)
          })
      })
    },
    ImportProduct ({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.post('import/add', item)
          .then(response => {
            resolve(response)
          }).catch(err => {
            reject(err)
          })
      })
    },
    confirmImport ({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.post('product/import', item)
          .then(response => {
            resolve(response)
          }).catch(err => {
            reject(err)
          })
      })
    }
  }
}
